<template>
	<div class="event col">
		<h1 class="theme_text center">已走演
			<template v-if="editable">
				{{"\xa0"}}<el-input-number v-model="counter" :min="0" />{{"\xa0"}}
			</template>
			<template v-else>{{_counter | numberFilter}}</template>
			個鄉鎮
			<template v-if="editable">
				<el-button type="primary" size="small" round @click="handleUpdateCounter()">儲存</el-button>
			</template>
			<template v-else>
				<el-button type="text" icon="el-icon-edit" @click="editable = true">編輯</el-button>
			</template>
		</h1>
		<div class="filters row-grid" align-x="right" align-y="center">
			<el-button @click="handleClearFilter()" type="info" size="mini" round :disabled="!_.some(query, Boolean)">清除篩選</el-button>
			<el-select v-model="query.city" filterable size="small" class="round" @change="handleCityFilterChange()">
				<template v-for="(val, key, i) in _filterList">
					<el-option :value="key" :key="i" />
				</template>
			</el-select>
			<el-select v-model="query.district" filterable size="small" class="round" @change="handleDistrictFilterChange()">
				<template v-for="(option, i) in _filterList[query.city]">
					<el-option :value="option" :key="i" />
				</template>
			</el-select>
		</div>
		<br>
		<table>
			<tr>
				<th class="theme_text">場次</th>
				<th class="theme_text">年份</th>
				<th class="theme_text">日期</th>
				<th class="theme_text">縣市</th>
				<th class="theme_text">鄉鎮</th>
				<th class="theme_text">地點</th>
				<th></th>
			</tr>
			<tr v-for="(item, i) in _eventList" :key="i">
				<td>{{item.session}}</td>
				<td>{{item.date | year}}</td>
				<td>{{item.date | date}}</td>
				<td>{{item.city}}</td>
				<td>{{item.district}}</td>
				<td>{{item.spot}}</td>
				<td>
					<div class="row">
						<el-button @click="handleEdit(item)" type="text" size="mini">編輯</el-button>
						<el-button @click="handleRemove(item.id)" type="danger" size="mini">刪除</el-button>
					</div>
				</td>
			</tr>
		</table>
		<br>
		<div class="row" align-x="between">
			<el-pagination :total="_total" :page-size="Number(query.size)" :current-page="Number(query.page)" @current-change="handleChangePage" layout="prev, pager, next" background />
			<el-button @click="dialogVisible = true" type="success" icon="el-icon-plus" round>新增</el-button>
		</div>
		<br>
		<el-dialog :visible="dialogVisible" @close="handleResetForm()">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
				<el-form-item label="場次" prop="session">
					<el-input v-model="ruleForm.session" :placeholder="`目前已辦 ${this._total} 場`" />
				</el-form-item>
				<el-form-item label="選擇（或填入作為新增）" required>
					<div class="row" gutter="10">
						<el-form-item prop="city">
							<el-autocomplete v-model="ruleForm.city" :fetch-suggestions="citySearch" placeholder="縣市" class="round" />
						</el-form-item>
						<el-form-item prop="district">
							<el-autocomplete v-model="ruleForm.district" :fetch-suggestions="districtSearch" placeholder="鄉鎮" class="round" />
						</el-form-item>
					</div>
				</el-form-item>
				<el-form-item label="日期" prop="date">
					<el-date-picker v-model="ruleForm.date" value-format="timestamp" />
				</el-form-item>
				<el-form-item label="地點" prop="spot">
					<el-input v-model="ruleForm.spot" placeholder="例：正修科技大學" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="row" align-x="center">
				<el-button @click="handleSave()" type="success">確定</el-button>
				<el-button @click="handleResetForm()" type="info">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions } from "vuex"
const ruleForm = {
	city: "",
	district: "",
	session: "",
	spot: "",
	date: ""
}
export default {
	data() {
		return {
			isNew: true,
			dialogVisible: false,
			query: new Object,
			counter: 0,
			editable: false,
			ruleForm,
			rules: {
				session: { required: true, message: "場次", trigger: "blur" },
				city: { required: true, message: "縣市", trigger: "change" },
				district: { required: true, message: "鄉鎮", trigger: "change" },
				spot: { required: true, message: "地點", trigger: "blur" },
				date: { required: true, message: "日期", trigger: "blur" }
			},
		}
	},
	computed: {
		...mapState("event", [
			"_total",
			"_counter",
			"_eventList",
			"_filterList"
		]),
	},
	filters: {
		year(val) {
			return val && moment(val).tz("Asia/Taipei").format("YYYY");
		},
		date(val) {
			return val && moment(val).tz("Asia/Taipei").format("M 月 D 日");
		}
	},
	methods: {
		...mapActions("event", [
			"_getList",
			"_createData",
			"_updateData",
			"_removeData",
			"_updateCounter",
		]),
		async handleSearch() {
			const query = {
				...this.$route.query,
				...this.query
			};
			this.$router.push({
				path: this.$route.path,
				query
			});
			await this._getList(query);
		},
		handleCityFilterChange() {
			this.query.district = "";
			this.query.page = 1;
			this.handleSearch();
		},
		handleDistrictFilterChange() {
			this.query.page = 1;
			this.handleSearch();
		},
		handleSave() {
			this.$refs["ruleForm"]
				.validate(async valid => {
					if (valid) {
						if (this.isNew) this.$message.success(await this._createData({
							query: this.query,
							data: this.ruleForm
						}));
						else this.$message.success(await this._updateData({
							query: this.query,
							data: this.ruleForm
						}));
						this.handleResetForm();
					}
				});
		},
		handleEdit(data) {
			this.ruleForm = this._.cloneDeep(data);
			this.isNew = false;
			this.dialogVisible = true;
		},
		handleResetForm() {
			this.isNew = true;
			this.dialogVisible = false;
			this.ruleForm = ruleForm;
			this.$refs["ruleForm"].resetFields();
		},
		handleRemove(id) {
			this.$confirm("確定要移除？", { type: "warning" })
				.then(async () => {
					this.$message.success(await this._removeData({
						id,
						query: this.query,
					}));
				})
		},
		citySearch(string, callback) {
			const results = string
				? Object.keys(this._filterList)
					.filter(item => item.includes(string))
				: Object.keys(this._filterList);
			// 調用 callback 返回建議列表的數據
			callback(results.map(value => ({ value })));
		},
		districtSearch(string, callback) {
			const list = this._filterList[this.ruleForm.city] || new Array;
			const results = string
				? list.filter(item => item.includes(string))
				: list;
			// 調用 callback 返回建議列表的數據
			callback(results.map(value => ({ value })));
		},
		handleChangePage(page) {
			this.$set(this.query, "page", page);
			this.handleSearch();
		},
		handleClearFilter() {
			this.$router.push(this.$route.path);
			this.$set(this.$data, "query", new Object);
			this._getList(this.query);
		},
		async handleUpdateCounter() {
			this.$message.success(await this._updateCounter(this.counter));
			this.editable = false;
		},
	},
	async created() {
		const {
			page = 1,
			size = 10,
			city = "",
			district = ""
		} = this.$route.query;
		this.$set(this.query, "page", Number(page));
		this.$set(this.query, "size", Number(size));
		this.$set(this.query, "city", city);
		this.$set(this.query, "district", district);
		await this._getList(this.query);
		this.counter = this._.cloneDeep(this._counter)
	}
}
</script>

<style lang="scss" scoped>
.filters {
	> * {
		margin-top: 10px;
		margin-left: 10px;
		max-width: 150px;
	}
}
table {
	width: 100%;
	background: #fff;
	border-radius: 10px;
	text-align: center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	padding: 1em 2em;
}
</style>